:root {
  --stepper-line-color: #27AF5D;
}

body {
  overflow: hidden;

  .validating-link-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-top: 10%;
    width: 100%;
  }

  .validating-link-container h6 {
    margin-top: 50px;
  }

  .MuiButtonBase-root {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #002B54;
    text-transform: none;
    border-radius: 100px;
    padding: 10px 24px;
  }

  .app-header {
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .core-spinner .spinner {
    margin-top: 0%;
  }

  .core-spinner {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
  }

  .expired-modal-message {
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    left: 50%;
    padding: 32px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
  }

  .tc-text-link {
    text-align: justify;
    word-wrap: break-word;
  }

  .MuiButtonBase-root.MuiButton-containedPrimary {
    background-color: #002B54;
    color: #ffffff;
  }

  .tc-bolder-text {
    font-weight: bold;
  }

  .tc-lighter-text {
    font-weight: 100;
  }

  .order-details-top-bar {
    background-color: #002B54;
  }

  .order-details-top-bar .order-details-tool-bar {
    padding: 0px;
  }

  .order-details-top-bar button {
    color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 0;
  }

  .back-button {
    cursor: pointer;
    margin-left: 30px;
    position: absolute;
  }

  .order-details-main-tabs {
    background-color: #FFF;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 56px;
    z-index: 9;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
    justify-content: space-around;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
    width: 100px;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root{
    color: #605D62;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
    color: #002B54;
    font-weight: 600;
  }

  .order-details-main-tabs .MuiTabs-indicator {
    background-color: #002B54;
    border-radius: 5px 5px 0px 0px;
    height: 5px;
  }

  .order-details {
    display: flex;
    flex-direction: column;
    height: 55vh;
    margin: 1% auto;
    overflow: auto;
    padding: 20px 40px;
    width: 70%;
  }

  .order-details-item {
    color: #605D62;
    font-weight: 300;
  }

  .order-details .order-details-item-container {
    align-items: baseline;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
  }

  .order-details .order-details-item-container span {
    color: #002B54;
    font-weight: bold;
  }

  .deliveries-container {
    display: flex;
    flex-direction: column;
    margin: 1% auto;
    padding: 20px 40px;
    width: 70%;
  }

  .deliveries-container .deliveries-tabs {
    background-color: #FFF;
    border-radius: 5px;
    border: 1.5px solid #ccc;
    margin-bottom: 10px;
    position: sticky;
    top: 140px;
    z-index: 9;
  }

  .deliveries-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
  }

  .deliveries-tabs .deliveries-tab {
    border-radius: 0px;
    flex-grow: 1;
  }
  .deliveries-tabs .medium-tab {
    border-left: 1.5px solid #ccc;
    border-right: 1.5px solid #ccc;
  }

  .deliveries-tabs .Mui-selected {
    background-color: #002B54;
    color: #FFF;
    font-weight: bold;
  }

  .deliveries-tabs .MuiTabs-indicator {
    background-color: #002B54;
  }

  .deliveries-container .deliveries-tabpanel {
    height: 55vh;
    overflow: auto;
    padding: 0 3px;
  }

  .deliveries-container .order-map-tabpanel {
    height: 55vh;
    overflow: auto;
    padding: 0 3px;
  }

  .deliveries-container .deliveries-tabpanel .deliveries-tab-content-tabpanel .deliveries-tab-no-content-msg {
    display: inline-block;
    font-weight: bold;
    margin-top: 30%;
    text-align: center;
    width: 100%;
  }

  .deliveries-card {
    border-radius: 9px;
    cursor: pointer;
  }

  .MuiCardContent-root:last-child {
    padding: 15px;
  }

  .main-tabs-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .main-tabs-container .main-tabs-right-arrow{
    font-size: 2rem;
  }
  
  .main-tabs-container-hover:hover {
    background-color: rgb(248, 248, 248);
  }

  .tabs-width-50 {
    max-width: 50%;
  }

  .tabs-width-40 {
    max-width: 40%;
  }
  
  .deliveries-card-content-box-left {
    display: flex;
    align-items: center;
  }

  .deliveries-card-content-box-left .deliveries-target-icon {
    width: 24px;
    height: 24px;
  }

  .ticket-details-main-nav {
    border-bottom: 1px solid #ccc;
  }

  .ticket-details-main-nav .MuiTabs-scroller .MuiTabs-flexContainer {
    min-height: 70px;
    justify-content: space-around;
  }

  .ticket-details-main-nav .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root{
    color: #605D62;
  }

  .ticket-details-main-nav .MuiTabs-scroller .MuiTabs-flexContainer .Mui-selected {
    color: #002B54;
    font-weight: 600;
  }

  .ticket-details-main-nav .MuiTabs-indicator {
    background-color: #002B54;
    border-radius: 5px 5px 0px 0px;
    height: 5px;
  }

  .tickets-details-main-container {
    height: 62vh;
    overflow: auto;
  }

  .ticket-details-tab-container {
    margin: auto;
    width: 50%;
  }
  
  .ticket-details-tab-container .ticket-details-events-container {
    margin-bottom: 50px;
    margin-left: 30px; 
    margin-top: 20px;
    max-width: 300px;
    padding-bottom: 40px;
  }

  .ticket-details-tab-container .ticket-details-details-container {
    margin: 10px;
  }
  
  .ticket-details-tab-container .ticket-details-events-container .ticket-details-events-title {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    width: 50%;
  }

  .ticket-details-tab-container .ticket-details-details-container .ticket-details-events-title {
    display: flex;
    padding-top: 20px;
  }

  .ticket-details-tab-container .ticket-details-details-container .ticket-details-events-title svg {
    margin-right: 10px;
  }
  
  .ticket-details-tab-container .ticket-details-accept-ticket-btn {
    background-color: #f2f2f2;
    margin-left: 30px;
    margin-top: 20px;
    width: 30%;
  }
  
  .ticket-details-tab-container .ticket-details-stepper .ticket-details-step .MuiStepLabel-label {
    color: #605D62;
  }
  
  .ticket-details-tab-container .ticket-details-events-container .ticket-details-stepper .MuiStepConnector-vertical {
    /* margin-bottom: -15px !important; */
    display: none;
  }

  .ticket-details-event-connector-content {
    margin-left: 12px;
    margin-bottom: -15px;
  }

  .ticket-details-event-connector {
    /* background-color: var(--stepper-line-color); */
    border-width: 0px;
    display: block;
    margin-left: 8px;
    margin-top: -23px;
    min-height: 30px;
    position: relative;
    width: 1.5%;
  }
  /* .ticket-details-tab-container .ticket-details-stepper .MuiStepConnector-root .MuiStepConnector-lineVertical {
    background-color: var(--stepper-line-color);
    border-width: 0px;
    margin-left: 8px;
    margin-top: -23px;
    min-height: 30px;
    position: relative;
    width: 1.5%;
  } */
  
  .ticket-details-tab-container .ticket-details-stepper .MuiStepLabel-labelContainer .MuiStepLabel-label {
    align-items: end;
    display: flex;
  }
  
  .ticket-details-tab-container .bw-counters-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter {
    background-color: #E5EAF0;
    margin: 20px 0px;
    padding: 30px 0px;
    text-align: center;
    width: 30%;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter h5 {
    color: #002B54;
    font-weight: 500;
  }

  .bw-table-container {
    height: 42vh;
    overflow: auto;
  }

  .ticket-details-tab-container .bw-table-container .bw-materials-table-container {
    display: table;
    max-width: 100%;
    table-layout: fixed;
  }

  .ticket-details-tab-container .bw-table-container .bw-materials-table .bw-materials-table-body .MuiTableRow-root .MuiTableCell-root {
    border: 1px solid rgba(224, 224, 224, 1);
    color: #484649;
  }

  .ticket-details-tab-container .ticket-details-events-container .ticket-details-events-title .ticket-details-events-title-main {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .ticket-details-tab-container .ticket-details-events-container .ticket-details-events-title .ticket-details-details-title-main {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .ticket-details {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
  }

  .ticket-details-item {
    color: #605D62;
    font-weight: 300;
  }

  .ticket-details .ticket-details-item-container {
    align-items: baseline;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
  }

  .ticket-details .ticket-details-item-container span {
    color: #002B54;
    font-weight: bold;
  }

  .ticket-details .ticket-details-secundary-products-box {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .ticket-details .ticket-details-secundary-products-box .ticket-details-secundary-product-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .map-empty-list-message-container {
    align-items: center;
    display: flex;
    height: 20vh;
    margin: auto;
    position: relative;
    width: 100%;
  }

  .map-empty-list-message-container .map-empty-list-message {
    font-weight: bold;
    text-align: center;
    width: 100%;
  }

}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 360px)
and (max-device-width : 740px) {}

@media only screen
and (min-device-width : 280px)
and (max-device-width : 720px) {
  .validating-link-container {
    margin-top: 50% !important;
  }

  .tc-dialog .MuiPaper-root {
    border-radius: 30px !important;
    max-height: 70% !important;
    min-width: 90% !important;
  }

  .tc-dialog-title {
    font-size: 1.3rem !important;
    text-align: center;
  }

  .deliveries-container {
    padding: 0px !important;
    width: 90% !important;
  }

  .MuiCardContent-root:last-child {
    padding: 6px !important;
  }

  .main-tabs-right-arrow {
    margin-left: 5px;
    margin-right: -5px;
  }

  .deliveries-card-content-text {
    font-size: 0.8rem !important;
  }

  .order-details {
    height: 60vh !important;
    padding: 20px 10px !important;
    width: 100% !important;
  }

  .deliveries-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
    height: 25px !important;
  }

  .deliveries-tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
    font-size: 0.8rem;
    height: 25px;
  }

  .main-tabs-container .main-tabs-right-arrow{
    font-size: 1.5rem !important;
  }

  .deliveries-card-content-box-left .deliveries-target-icon {
    width: 40px !important;
    height: 40px !important;
  }

  .expired-modal-message {
    width: 70% !important;
  }

  .ticket-details-tab-container {
    width: 100% !important;
  }

  .ticket-details-tab-container .ticket-details-events-container {
    margin-left: 5px !important;
    margin-top: 10px !important;
  }

  .ticket-details-tab-container .ticket-details-stepper .MuiStepConnector-root .MuiStepConnector-lineVertical {
    width: 0.25rem !important;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter {
    max-height: 100px;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter .bw-text-measures {
    font-size: 1rem;
    margin: auto 5px;
  }

  .footer-container .footer .title-footer-container .footer-ca-icon {
    height: 30px !important;
    width: 30px !important;
  }

  .footer-container .footer .title-footer-container .footer-text {
    width: 50% !important;
    font-size: 12px !important;
  }

  .deliveries-container .deliveries-tabpanel {
    height: 58vh !important;
  }

  .deliveries-container .order-map-tabpanel {
    height: 58vh !important;
  }

  .footer-container > .footer > .footer-markets-icons {
    margin-top: 0px !important;
  }

  .footer-container .footer .footer-markets-icons .footer-market-iphone-icon {
    height: 40px !important;
    width: 120px !important;
  }

  .footer-container .footer .footer-markets-icons .footer-market-google-icon {
    height: 40px !important;
    width: 120px !important;
  }

  .bw-table-container {
    height: 40vh !important;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-device-width : 721px)
and (max-device-width : 1200px) {
  .validating-link-container {
    margin-top: 50% !important;
  }

  .order-details {
    width: 100% !important;
    padding: 20px 30px !important;
  }

  .deliveries-container {
    width: 100% !important;
  }

  .ticket-details-tab-container {
    width: 90% !important;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter {
    max-height: 100px;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter .bw-text-measures {
    font-size: 1rem;
    margin: auto 5px;
  }

  .footer-container > .footer > .title-footer-container > .footer-text {
    width: 50% !important;
    font-size: 14px !important;
  }

  .deliveries-container .deliveries-tabpanel {
    height: 60vh !important;
  }

  .footer-container .footer .footer-markets-icons .footer-market-iphone-icon {
    height: 40px !important;
    width: 120px !important;
  }

  .footer-container .footer .footer-markets-icons .footer-market-google-icon {
    height: 40px !important;
    width: 120px !important;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen
and (min-device-width : 150px)
and (max-device-width : 319px) {
  .validating-link-container {
    margin-top: 50% !important;
  }

  .order-details-item-container p{
    font-size: 0.6rem;
  }

  .order-details-item-container span {
    font-size: 0.6rem;
    text-align: right;
  }

  .order-details-main-tabs .MuiTabs-scroller .MuiTabs-flexContainer button {
    font-size: 0.6rem;
  }

  .ticket-details-tab-container .ticket-details-events-container {
    font-size: 0.6rem !important;
    margin-left: 2px !important;
    margin-top: 10px !important;
  }

  .ticket-details-tab-container .ticket-details-events-container .ticket-details-events-title .ticket-details-events-title-main {
    font-size: 0.8rem !important;
    margin: 0 !important;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter {
    max-height: 100px;
  }

  .footer-container > .footer > .title-footer-container > .footer-text {
    font-size: 12px !important;
    margin: 10px auto !important;
    width: 60% !important;
  }

  .ticket-details-tab-container .bw-counters-box .bw-counter .bw-text-measures {
    font-size: 0.7rem;
    margin: auto 5px;
  }

  .deliveries-container .deliveries-tabpanel {
    height: 70vh !important;
  }

  .footer-container > .footer > .footer-markets-icons {
    margin-top: 0px !important;
  }

  .footer-container .footer .footer-markets-icons .footer-market-iphone-icon {
    height: 20px !important;
    width: 100px !important;
  }

  .footer-container .footer .footer-markets-icons .footer-market-google-icon {
    height: 20px !important;
    width: 100px !important;
  }
}
